exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-chiropractic-jsx": () => import("./../../../src/pages/chiropractic.jsx" /* webpackChunkName: "component---src-pages-chiropractic-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-dizziness-handicap-inventory-jsx": () => import("./../../../src/pages/dizziness-handicap-inventory.jsx" /* webpackChunkName: "component---src-pages-dizziness-handicap-inventory-jsx" */),
  "component---src-pages-extra-forms-jsx": () => import("./../../../src/pages/extra-forms.jsx" /* webpackChunkName: "component---src-pages-extra-forms-jsx" */),
  "component---src-pages-fall-efficacy-scale-jsx": () => import("./../../../src/pages/fall-efficacy-scale.jsx" /* webpackChunkName: "component---src-pages-fall-efficacy-scale-jsx" */),
  "component---src-pages-foot-and-ankle-disability-index-jsx": () => import("./../../../src/pages/foot-and-ankle-disability-index.jsx" /* webpackChunkName: "component---src-pages-foot-and-ankle-disability-index-jsx" */),
  "component---src-pages-forms-jsx": () => import("./../../../src/pages/forms.jsx" /* webpackChunkName: "component---src-pages-forms-jsx" */),
  "component---src-pages-headache-disability-index-jsx": () => import("./../../../src/pages/headache-disability-index.jsx" /* webpackChunkName: "component---src-pages-headache-disability-index-jsx" */),
  "component---src-pages-ideal-protein-jsx": () => import("./../../../src/pages/ideal-protein.jsx" /* webpackChunkName: "component---src-pages-ideal-protein-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-international-knee-documentation-jsx": () => import("./../../../src/pages/international-knee-documentation.jsx" /* webpackChunkName: "component---src-pages-international-knee-documentation-jsx" */),
  "component---src-pages-lower-extremity-functional-scale-jsx": () => import("./../../../src/pages/lower-extremity-functional-scale.jsx" /* webpackChunkName: "component---src-pages-lower-extremity-functional-scale-jsx" */),
  "component---src-pages-massage-jsx": () => import("./../../../src/pages/massage.jsx" /* webpackChunkName: "component---src-pages-massage-jsx" */),
  "component---src-pages-medical-release-form-jsx": () => import("./../../../src/pages/medical-release-form.jsx" /* webpackChunkName: "component---src-pages-medical-release-form-jsx" */),
  "component---src-pages-modified-oswestry-jsx": () => import("./../../../src/pages/modified-oswestry.jsx" /* webpackChunkName: "component---src-pages-modified-oswestry-jsx" */),
  "component---src-pages-neck-disability-index-questionnaire-jsx": () => import("./../../../src/pages/neck-disability-index-questionnaire.jsx" /* webpackChunkName: "component---src-pages-neck-disability-index-questionnaire-jsx" */),
  "component---src-pages-new-patient-form-jsx": () => import("./../../../src/pages/new-patient-form.jsx" /* webpackChunkName: "component---src-pages-new-patient-form-jsx" */),
  "component---src-pages-pediatric-intake-form-jsx": () => import("./../../../src/pages/pediatric-intake-form.jsx" /* webpackChunkName: "component---src-pages-pediatric-intake-form-jsx" */),
  "component---src-pages-personal-injury-form-jsx": () => import("./../../../src/pages/personal-injury-form.jsx" /* webpackChunkName: "component---src-pages-personal-injury-form-jsx" */),
  "component---src-pages-physical-therapy-jsx": () => import("./../../../src/pages/physical-therapy.jsx" /* webpackChunkName: "component---src-pages-physical-therapy-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-quickdash-outcome-measure-jsx": () => import("./../../../src/pages/quickdash-outcome-measure.jsx" /* webpackChunkName: "component---src-pages-quickdash-outcome-measure-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */),
  "component---src-pages-upper-extremity-functional-index-jsx": () => import("./../../../src/pages/upper-extremity-functional-index.jsx" /* webpackChunkName: "component---src-pages-upper-extremity-functional-index-jsx" */),
  "component---src-pages-workers-compensation-form-jsx": () => import("./../../../src/pages/workers-compensation-form.jsx" /* webpackChunkName: "component---src-pages-workers-compensation-form-jsx" */)
}

